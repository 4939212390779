import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  POST_MEDICAL_GROUP_INVITATION_REQUEST,
  getOrganizationDomainListFailure,
  POST_ACCEPT_REQUEST_MEDICAL_GROUP_REQUEST,
  POST_ACCESS_REQUEST_MEDICAL_GROUP_REQUEST,
  POST_ACCESS_REQUEST_MEDICAL_GROUP_FAILURE,
  GET_MEDICAL_GROUPS_INVITATIONS_LIST_REQUEST,
  GET_MEDICAL_GROUPS_INVITATIONS_LIST_FAILURE,
  GET_ORGANIZATION_DOMAIN_LIST_REQUEST,
  GET_ORGANIZATION_DOMAIN_LIST_FAILURE,
  getInvitationsListSuccess,
  POST_MEDICAL_GROUP_INVITATION_FAILURE,
  POST_ACCEPT_REQUEST_MEDICAL_GROUP_FAILURE,
  acceptRequestSuccess,
  PUT_ACCESS_REQUEST_MEDICAL_GROUP_REQUEST,
  putAccessRequestRequest,
  PUT_ACCESS_REQUEST_MEDICAL_GROUP_FAILURE,
  PUT_REJECT_MEDICAL_GROUP_REQUEST
} from '../actions/medicialGroupActions'
import history from '../routers/history'
import { SIGN_UP_STEPS } from '../config/form-settings'
import AuthService from '../services/AuthService'
import { getOrganizationDomainListSuccess } from '../actions/medicialGroupActions'
import {
  postAccessRequestAPICall,
  postAcceptRequestAPICall,
  getInvitionsListAPICall,
  getOrganizationDomainListAPICall,
  putAccessRequestAPICall
} from '../apis/medicialGroup'
import {
  successNotification,
  dataLoadingNotification,
  clearNotification
} from '../utils/notification-helpers'
import store from 'store'
import { INVITE_ORG, LOCAL_STORAGE_AUTH_KEY, LOCAL_STORAGE_NEW_ORGANIZATION_KEY } from '../config/constants'
import { t } from '../i18n';
import { postCreateGroupRequest } from '../actions/groupActions'
import { patchExistingUserAPICall } from '../apis/user'
import { postNotificationToSlackAPICall, postNotificationToSlackForDeleteGroupAPICall, postRequestAccessJoinAffiliateZapierAPICall, postSignUpInfoToZapierAPICall } from '../apis/slack'
import { getGroupAPICall } from '../apis/group'

function* getInvitionsListHandler() {
  try {
    const auth = new AuthService()
    let userProfile = auth.getUserProfile()

    if (!userProfile) {
      userProfile = yield call(auth.updateUserProfile, store.get(LOCAL_STORAGE_AUTH_KEY.ACCESS_TOKEN))
      const emailID = userProfile?.email

      if (emailID) {
        const { responseJSON, responseOk } = yield call(getInvitionsListAPICall, emailID)
        if (responseOk) {
          if (responseJSON?.length === 0) {
            // yield put(postCreateGroupRequest({ userGroupName: `${userProfile?.name}'s Medical Group`, dontRedrict: true }))
            // history.replace(`/signup/${SIGN_UP_STEPS['success'].path}`)
          }
          yield put(getInvitationsListSuccess(responseJSON))
        } else {
          // yield put(postCreateGroupRequest({ userGroupName: `${userProfile?.name}'s Medical Group`, dontRedrict: true }))
          // history.replace(`/signup/${SIGN_UP_STEPS['success'].path}`)
        }
      }
    } else {
      const emailID = userProfile?.email

      if (emailID) {
        const { responseJSON, responseOk } = yield call(getInvitionsListAPICall, emailID)
        if (responseOk) {
          if (responseJSON?.length === 0) {
            // yield put(postCreateGroupRequest({ userGroupName: `${userProfile?.name}'s Medical Group`, dontRedrict: true }))
            // history.replace(`/signup/${SIGN_UP_STEPS['success'].path}`)
          }
          yield put(getInvitationsListSuccess(responseJSON))
        } else {
          // yield put(postCreateGroupRequest({ userGroupName: `${userProfile?.name}'s Medical Group`, dontRedrict: true }))
          // history.replace(`/signup/${SIGN_UP_STEPS['success'].path}`)
        }
      }
    }


  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: GET_MEDICAL_GROUPS_INVITATIONS_LIST_FAILURE,
      message: e.message
    })
  }
}

function* getOrganizationDomainHandler() {
  try {
    const auth = new AuthService()
    let userProfile = auth.getProfileRequestBodyForUpdate()
    const emailID = userProfile?.email
    const { app_metadata } = userProfile || {}

    const { responseJSON, responseOk } = yield call(getOrganizationDomainListAPICall, emailID)

    // if (app_metadata && app_metadata?.affiliated_group === "") {
    //   yield put(postCreateGroupRequest({ userGroupName: `${userProfile?.name}'s Medical Group`, dontRedrict: true }))
    // }
    if (responseOk) {
      yield put(getOrganizationDomainListSuccess(responseJSON))
    } else {
      if (responseJSON?.detail) {
        yield put(getOrganizationDomainListFailure(responseJSON?.detail))
      }
    }
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: GET_ORGANIZATION_DOMAIN_LIST_FAILURE,
      message: e.message
    })
  }
}

function* sendInvitationHandler({ payload }) {
  try {
    const { organizationGuid } = payload;
    const { responseJSON, responseOk } = yield call(getOrganizationDomainListAPICall, organizationGuid)

    return { responseJSON, responseOk };
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: POST_MEDICAL_GROUP_INVITATION_FAILURE,
      message: e.message
    })
  }
}

function* accessRequestHandler({ payload }) {
  try {
    dataLoadingNotification('')
    const { organization_guid, organization_name } = payload;
    const auth = new AuthService()
    const userProfile = auth.getUserProfile()
    const { responseJSON, responseOk } = yield call(
      postAccessRequestAPICall,
      { organization_name, organization_guid, user_id: userProfile.sub, user_email: userProfile.email }
    )
    yield call(postRequestAccessJoinAffiliateZapierAPICall, { organization_name, organization_guid, user_id: userProfile.sub, user_email: userProfile.email })
    // const userMemberOf = store.get(LOCAL_STORAGE_NEW_ORGANIZATION_KEY)
    let updatedColleague = {
      app_metadata: {
        affiliated_group: "",
        admin: '0',
        main: '0',
        approved_facilities: [],
        associated_groups: [],
      },
      user_metadata: {
        name: userProfile.name,
        sub_speciality: '',
        title: '',
        home_facility: '',
        credentials: ''
      }
    }

    const { responseOk: groupStatus, responseJSON: groupJson } = yield call(getGroupAPICall)

    const { responseOk: patchedExistingUserOk, responseJSON: patchedExistingUserResponse } = yield call(
      patchExistingUserAPICall,
      userProfile.sub, { user: userProfile, appMetadata: updatedColleague }
    )
    if (patchedExistingUserOk) yield call(postSignUpInfoToZapierAPICall, { jsonContent: patchedExistingUserResponse })
    if (groupStatus) {
      yield call(postNotificationToSlackForDeleteGroupAPICall, { title: '[Delete group and stripe]', description: '`Delete group and stripe`, Group Guid: ', jsonContent: { groupJson } })
    }
    if (responseOk) {
      clearNotification()
      store.set(INVITE_ORG, { groupId: responseJSON?.organization_guid, groupName: responseJSON?.organization_name })
      successNotification(t("medical_group_saga.request_success"))
      history.replace(`/signup/${SIGN_UP_STEPS['success'].path}`)
    }
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: POST_ACCESS_REQUEST_MEDICAL_GROUP_FAILURE,
      message: e.message
    })
  }
}

function* acceptRequestHandler({ payload }) {
  try {
    dataLoadingNotification('')
    const auth = new AuthService()
    const userProfile = auth.getUserProfile()
    const userId = userProfile && userProfile.sub

    const { invitation_guid } = payload;

    const { responseOk: groupStatus, responseJSON: groupJson } = yield call(getGroupAPICall)

    const { responseJSON, responseOk } = yield call(
      postAcceptRequestAPICall,
      invitation_guid
    )
    if (responseOk && responseJSON) {
      // yield put(putAccessRequestRequest(responseJSON))
      const updatedAppMetadata = {
        app_metadata: responseJSON.app_metadata,
        user_metadata: responseJSON.user_metadata
      }
      const { responseOk: userUpdateOk, responseJSON: userUpdateResponse } = yield call(patchExistingUserAPICall, userId, { appMetadata: updatedAppMetadata })
      if (userUpdateOk) {
        if (userUpdateOk) yield call(postSignUpInfoToZapierAPICall, { jsonContent: userUpdateResponse })
        store.set(INVITE_ORG, { groupId: responseJSON?.organization_guid, groupName: responseJSON?.organization_name })
        if (groupStatus) {
          yield call(postNotificationToSlackForDeleteGroupAPICall, { title: '[Delete group and stripe]', description: '`Delete group and stripe`, Group Guid: ', jsonContent: groupJson })
        }
        yield put(acceptRequestSuccess(responseJSON))
        successNotification(t('medical_group_saga.accept_invitation'))
        clearNotification();
        history.replace(`/signup/${SIGN_UP_STEPS['success'].path}`)
      }
    }
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: POST_ACCEPT_REQUEST_MEDICAL_GROUP_FAILURE,
      message: e.message
    })
    clearNotification();
  }
}


function* accessRequestAndRejectRequestHandler({ payload }) {
  try {
    yield call(putAccessRequestAPICall, payload)
  } catch (e) {
    yield put({
      type: PUT_ACCESS_REQUEST_MEDICAL_GROUP_FAILURE,
      message: e.message
    })
  }
}

function* rejectAccessRequestHandler({ payload }) {
  try {
    dataLoadingNotification('')
    const auth = new AuthService()
    const userProfile = auth.getUserProfile()
    const userId = userProfile && userProfile.sub
    const { invitation_guid } = payload;
    const { responseJSON, responseOk } = yield call(postAcceptRequestAPICall, invitation_guid)
    yield call(putAccessRequestAPICall, { ...responseJSON, accept_or_reject: "reject" })
    // yield put(putAccessRequestRequest({ ...responseJSON, accept_or_reject: "reject" }))
    clearNotification();
  }
  catch (e) {
    clearNotification();
    yield put({
      type: PUT_ACCESS_REQUEST_MEDICAL_GROUP_FAILURE,
      message: e.message
    })
  }
}


export function* configurationSaga() {
  yield takeLatest(GET_MEDICAL_GROUPS_INVITATIONS_LIST_REQUEST, getInvitionsListHandler)
  yield takeLatest(GET_ORGANIZATION_DOMAIN_LIST_REQUEST, getOrganizationDomainHandler)
  yield takeLatest(POST_MEDICAL_GROUP_INVITATION_REQUEST, sendInvitationHandler)
  yield takeLatest(POST_ACCESS_REQUEST_MEDICAL_GROUP_REQUEST, accessRequestHandler)
  yield takeLatest(POST_ACCEPT_REQUEST_MEDICAL_GROUP_REQUEST, acceptRequestHandler)
  yield takeLatest(PUT_ACCESS_REQUEST_MEDICAL_GROUP_REQUEST, accessRequestAndRejectRequestHandler)
  yield takeLatest(PUT_REJECT_MEDICAL_GROUP_REQUEST, rejectAccessRequestHandler)
}
